.animated {
  opacity: 0;
}

@font-face {
  font-family: BebasNeue;
  src: url('../font/BebasNeue.otf');
}

/*
1. Basic css
2. Navigation Css
3. Landing Css
4. Footer Css
*/

/* 1. Basic css */

body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

a,
button,
input {
  text-decoration: none !important;
  outline: none !important;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 50px;
}

.visible {
  opacity: 1;
}

/* 2. Navigation Css */

.navbar-brand {
  background-image: url('../img/logo.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
}

.navbar {
  background-color: #fff;
  padding: 0px 40px;
  height: 80px;
  line-height: 80px;
  display: flex;
  -webkit-transition: 0.4s all ease-out;
  -moz-transition: 0.4s all ease-out;
  -ms-transition: 0.4s all ease-out;
  -o-transition: 0.4s all ease-out;
  transition: 0.4s all ease-out;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.10);
}

.navbar * {
  -webkit-transition: 0.4s all ease-out;
  -moz-transition: 0.4s all ease-out;
  -ms-transition: 0.4s all ease-out;
  -o-transition: 0.4s all ease-out;
  transition: 0.4s all ease-out;
}

.navbar-default .navbar-nav > li > a {
  color: #0d0f2a;
  margin: 0 10px;
  font-size: 15px;
  //padding: 10px 25px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
}

.navbar-default .navbar-nav > li > .social {
  margin: 0 2px;
  width: auto;
  text-align: center;
}

.navbar-default .navbar-nav > li > a:hover {
  color: #afb71d;
}

.navbar-default .navbar-nav > .dropdown > a:after {
  display: none;
}

.navbar-default .navbar-nav > li > a:after {
  content: '';
  width: 0;
  position: absolute;
  height: 5px;
  background-color: #afb71d;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transition: 0.4s all ease;
  -moz-transition: 0.4s all ease;
  -ms-transition: 0.4s all ease;
  -o-transition: 0.4s all ease;
  transition: 0.4s all ease;
}

.navbar-default .navbar-nav > li > a:hover:after {
  width: 100%;
  -webkit-transition: 0.4s all ease;
  -moz-transition: 0.4s all ease;
  -ms-transition: 0.4s all ease;
  -o-transition: 0.4s all ease;
  transition: 0.4s all ease;
}

.navbar-default .navbar-nav > li > .btn-sign:hover:after {
  width: 0%;
  -webkit-transition: 0.4s all ease;
  -moz-transition: 0.4s all ease;
  -ms-transition: 0.4s all ease;
  -o-transition: 0.4s all ease;
  transition: 0.4s all ease;
}

.navbar-default .navbar-nav > li > .social:hover:after {
  width: 0%;
  -webkit-transition: 0.4s all ease;
  -moz-transition: 0.4s all ease;
  -ms-transition: 0.4s all ease;
  -o-transition: 0.4s all ease;
  transition: 0.4s all ease;
}

.navbar-default .navbar-nav > li > .social > .fa {
  color: #c5c6d9;
  font-size: 18px
}

.navbar-default .navbar-nav > li > .social:hover > .fa,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  color: #afb71d;
  background-color: transparent;
}

.navbar-default .navbar-nav > .active > a:after,
.navbar-default .navbar-nav > .active > a:focus:after,
.navbar-default .navbar-nav > .active > a:hover:after {
  width: 100%;
}

.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #afb71d;
  background-color: transparent;
}

/*mega menu*/
.nav > li.dropdown {
  position: relative;
}

.navbar .dropdown .dropdown-menu {
  background-color: #fff;
  right: -360px;
  left: -360px;
  margin: 0 auto;
}

.dropdown:focus .dropdown-menu,
.dropdown:hover .dropdown-menu {
  opacity: 1;
  -webkit-transform: rotateX(0) translateZ(0);
  transform: rotateX(0) translateZ(0);
}

.dropdown:hover > .dropdown-toggle:after {
  transform: rotate(180deg);
}

.caret {
  border: none;
}

.dropdown .dropdown-toggle::after {
  font-family: "FontAwesome";
  text-decoration: none;
  content: '\f107';
  position: absolute;
  margin-left: 5px;
  transition: all .3s ease;
}

.banner-container .dropdown-menu {
  margin: 0px 390px 0px 0px;
}

.dropdown-menu {
  padding: 8px;
  margin-right: 90px;
  top: 60px;
  display: block !important;
  width: 250px;
  box-shadow: none;
  margin: 0px 90px;
  -webkit-box-shadow: none;
  opacity: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  transition: opacity 0.1s 0.1s, -webkit-transform 0.4s cubic-bezier(0.17, 0.67, 0.59, 1.21);
  transition: transform 0.4s cubic-bezier(0.17, 0.67, 0.59, 1.21), opacity 0.1s 0.1s;
  transition: transform 0.4s cubic-bezier(0.17, 0.67, 0.59, 1.21), opacity 0.1s 0.1s, -webkit-transform 0.4s cubic-bezier(0.17, 0.67, 0.59, 1.21);
  background-color: #fff;
  box-shadow: 0 2px 33px 0 rgba(0, 0, 0, 0.08);
  text-align: left;
  border-radius: 8px !important;
  display: flex;
  flex-wrap: wrap;
  border: none;
}

.dropdown-menu:after {
  border: 20px solid transparent;
  border-bottom-color: #fff;
  content: "";
  height: 0;
  left: 42%;
  opacity: 1;
  -webkit-transition: 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1);
  -webkit-transition-delay: 0.95s;
  transition-delay: 0.95s;
  position: absolute;
  top: -39px;
  width: 1px;
}

.dropdown .dropdown-menu > li {
  padding: 5px 10px;
  transition: all .6s ease-in-out;
}

.dropdown-menu li:hover {
  background-color: #f6f7f8;
  border-radius: 3px;
}

.dropdown .dropdown-menu > li > a {
  white-space: unset;
}

.dropdown .dropdown-menu > li > a:focus,
.dropdown .dropdown-menu > li > a:hover {
  color: #262626;
  text-decoration: none;
  background: transparent;
}

.dropdown-menu > li > a > h4 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  padding: 5px 0px;
}

.dropdown-menu > li > a > span {
  color: #70787f;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 30px;
}

.dropdown-menu > li ul > li > a:hover,
.dropdown-menu > li ul > li > a:focus {
  text-decoration: none;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  //color: #fff;
  background-color: transparent !important;
}

.navbar-white .navbar-nav > .open > a,
.navbar-white .navbar-nav > .open > a:focus,
.navbar-white .navbar-nav > .open > a:hover {
  color: #ff7229;
  background-color: transparent;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  //background-color: transparent;
  //border-color: #337ab7;
  //color: #ff7229;
}

.dropdown a i {
  margin-left: 10px;
}

.dropdown-menu > li > a {
  font-weight: 600;
  padding: 15px;
}

@media (min-width: 768px) {
  .nav-white {
    background-color: #fff;
    border-bottom: rgba(255, 255, 255, 0.29) solid 0px;
    -moz-box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
  }
  .nav-white .navbar-brand {
    background-image: url('../img/logo.png');
  }
  .nav-white .navbar-nav > .active > a,
  .nav-white .navbar-nav > .active > a:focus,
  .nav-white .navbar-nav > .active > a:hover {
    color: #afb71d;
  }
  .nav-white .navbar-nav > li > a,
  .nav-white .navbar-nav > li > a:focus,
  .nav-white .navbar-nav > li > a:hover {
    color: #0d0f2a;
  }
  .nav-white .navbar-nav > li > a:after {
    background-color: #afb71d;
  }
}

@media (max-width: 767px) {
  .dropdown-menu {
    -webkit-transform: rotateX(0) translateZ(0);
    transform: rotateX(0) translateZ(0);
    opacity: 1 !important;
    display: none !important;
  }
  .dropdown:hover > .dropdown-toggle:after {
    transform: rotate(0deg);
  }
  .dropdown.open .dropdown-menu {
    display: block !important;
  }
  .navbar-default .navbar-nav > .open > a,
  .navbar-default .navbar-nav > .open > a:focus,
  .navbar-default .navbar-nav > .open > a:hover {
    color: #383838;
    background-color: transparent;
  }
  .dropdown .dropdown-menu > li {
    padding: 0px 10px;
    transition: all .6s ease-in-out;
  }
  .dropdown .dropdown-menu > li:last-child {
    border-bottom: 0px;
  }
  .nav > li > a {
    padding: 10px;
  }
  .dropdown-menu > li {
    width: 100%;
  }
  .dropdown {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .dropdown-menu > li > a > h4 {
    padding: 0px;
    margin: 5px 0px;
  }
  .navbar-nav .open .dropdown-menu {
    position: relative !important;
    right: 0px !important;
    left: 0px !important;
    top: 0;
  }
  .navbar {
    display: block;
  }
  .navbar {
    padding: 10px 20px;
    height: auto;
  }
  .navbar-brand {
    margin-left: 0px;
  }
  .navbar-default {
    background-color: #fff;
    border-bottom: rgba(255, 255, 255, 0.29) solid 0px;
    -moz-box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
  }
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:focus,
  .navbar-default .navbar-nav > .active > a:hover {
    color: #afb71d;
  }
  .navbar-default .navbar-nav > li > a,
  .navbar-default .navbar-nav > li > a:focus,
  .navbar-default .navbar-nav > li > a:hover {
    color: #0d0f2a;
  }
  .navbar-default .navbar-nav > li > a:after {
    display: none;
  }
  .navbar-default .navbar-nav > li > a {
    text-align: left;
  }
  .btn-sign {
    text-align: center !important;
    margin: 0 0px !important;
  }
  .navbar-default .navbar-toggle:focus,
  .navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle {
    background-color: transparent;
    border: none;
    margin-right: 0;
  }
}

/* 3. Landing Css */

.landing-home {
  color: #fff;
  background-size: 100%;
  background-repeat: no-repeat;
  background: url(../img/bg.png);
  height: 780px;
  display: flex;
  display: -ms-flexbox;
  position: relative;
  align-items: center;
  text-align: center;
  -ms-flex-align: center;
  top: 100px;
  margin-top: -100px;

  @media (max-width: 996px) {
    height: auto;
    padding-bottom: 100px;
    padding-top: 150px;
  }
}

.overlay {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.35);
  height: 780px;
}

.landing-home1 {
  background: url(../img/bg-about.png);
}

.landing-home2 {
  background: url(../img/bg-boster.png);
}

.landing-home3 {
  background: url(../img/bg-com.png);
}

.landing-home4 {
  background: url(../img/bg-req.png);
}

.flex-col {
  flex-direction: column;
}

.landing-home h1 {
  margin: 0px;
  font-size: 43px;
  position: relative;
  text-transform: uppercase;
  font-family: BebasNeue;
  letter-spacing: 4px;
}

.landing-home h3 {
  font-size: 30px;
  position: relative;
  margin: 70px 0 40px;
  font-weight: 600;
  letter-spacing: 2px;
}

.landing-home p {
  margin: 0px;
  font-size: 16px;
  position: relative;
  letter-spacing: 1px;
  line-height: 32px;
  font-weight: 600;
}

.program-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  z-index: 100;
}

.p-box {
  padding: 45px 30px;
  box-shadow: 0 15px 54px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 519px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  width: 370px;
  transition: all 0.6s ease-in-out;
  border: 2px solid #f6f6f6;
}

.p-box:hover, .pro-list-box:hover {
  box-shadow: none;
}

.p-icon-1 {
  margin-right: 20px;
  margin-bottom: 20px;
}

.p-head-1 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.pro-list-box {
  padding: 45px 45px;
  box-shadow: 0 15px 54px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  background: #fff;
  color: #4c4c4c;
  font-size: 16px;
  line-height: 46px;
  font-weight: 600;
  transition: all 0.6s ease-in-out;
  border: 2px solid #f6f6f6;
}

.pro-list-box-center {
  justify-content: center;
  align-items: center;
}

.p-text {
  font-size: 15px;
  color: #666666;
  line-height: 34px;
  font-weight: 500;
}

.p-head {
  font-size: 20px;
  color: #000000;
  font-weight: 600;
  letter-spacing: 1px;
}

.flex-inner {
  background-image: url(../img/flex-bg.png);
  background-size: cover;
  background-position: center;

  position: relative;
  padding: 50px 0px;
}

.flex-inner .fw-special-title {
  margin: 0px 0px 40px;
  @media (max-width: 990px) {
    margin: 40px 0 20px;
  }
}

.overlay-con {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.28);
  height: 100%;
  z-index: -999;
}

.flex-post__content-list {
  height: 410px;
  width: 1170px;
  background: #fff;
  border-radius: 10px;
  padding: 65px 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.flex-post__content-list li {
  color: #000;
  font-size: 15px;
  text-align: justify;
  line-height: 30px;
  letter-spacing: 1px;
  width: 710px;
  margin: 0 auto;
}

.flex-post__content-list li:before {
  background: #afb71d;
  content: "";
  display: inline-block;
  height: 9px;
  position: relative;
  vertical-align: middle;
  width: 9px;
  margin-right: 20px;
  border-radius: 50%;
}

.fw-special-title {
  margin: 0px 0px 80px;
  font-size: 43px;
  position: relative;
  text-transform: uppercase;
  font-family: BebasNeue;
  letter-spacing: 4px;
  text-align: center;
}

.text-white {
  color: #fff;
}

.fw-special-title:after {
  content: '';
  display: block;
  height: 5px;
  width: 70px;
  background: #afb71d;
  margin: 20px auto 0 auto;
  -webkit-animation: title-border .5s ease-in;
  -moz-animation: title-border .5s ease-in;
  -o-animation: title-border .5s ease-in;
  animation: title-border .5s ease-in;
}

.section {
  padding: 75px 0px 0px;
}

.head-content {
  font-size: 16px;
  color: #4c4c4c;
  line-height: 42px;
  font-weight: 500;
  letter-spacing: 0.55px;
  font-weight: 600;
}

.fw-btn {
  margin: 100px auto 0px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 574px;
  padding: 0px 40px;
  height: 74px;
  line-height: 74px;
  border-radius: 10px;
  background-color: #afb71d;
  transition: all .6s ease;
  border: 2px solid #afb71d;
}

.fw-btn:hover {
  border: 2px solid #afb71d;
  color: #afb71d;
  background: transparent;
}

.test-inner {
  background: #f9fafb;
  position: relative;
}

.test-list-inner2 {
  justify-content: space-around;
  display: flex;
  align-items: center;
  list-style-type: none;
  position: relative;
  margin: 0px;
  padding: 70px 0px;
  flex-wrap: wrap;
}

.contact-main-container {
  position: relative;
  padding: 100px 0px 0px;
}

.contact-top {
  position: relative;
  z-index: 2;
}

.contact-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}

.contact-heading-inner {
  font-size: 30px;
  font-weight: 600;
}

.contact-main-container:before {
  content: '';
  background-color: #1a1a1a;
  height: 35%;
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
}

.contact-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.foot-top-right {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.foot-top-right ul {
  width: calc(33.33% - 0px);
}

.foot-top-left {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.foot-top-left p {
  font-size: 16px;
  color: #fff;
  margin: 0px 0px;
  line-height: 30px;
  letter-spacing: 1px;
  font-weight: 500;
}

.foot-top-left p span {
  color: #b0b0b0;
}

.foot-top-right ul li {
  margin-bottom: 10px;
}
.foot-top-left, .foot-top-right {
  a, p.foot-text {
    @media (max-width: 776px) {
      font-size: 12px !important;
      line-height: 24px !important;
    }
  }
}

.color-green a {
  color: #afb71d !important;
}

.foot-list-bold {
  margin-bottom: 20px !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
}

.foot-top-right ul li a {
  color: rgba(32, 34, 42, .55);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: all 0.4s ease-in-out;
  line-height: 29px;
}

.contact-bottom {
  background-color: #fff;
  padding: 5%;
  position: relative;
  box-shadow: 0 0px 65px 0px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  flex-direction: column;
}

.faq-bottom-inner p {
  text-align: center;
}

.contact-inner p {
  color: #000000;
  text-align: justify;
  width: 50%;
  font-size: 16px;
  line-height: 44px;

  @media (max-width: 776px) {
    font-size: 12px;
    line-height: 24px;
  }
}

button, a {
  &.contact-button {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0px 30px;
    height: 74px;
    line-height: 74px;
    border-radius: 10px;
    background-color: #afb71d;
    transition: all .6s ease;
    border: 2px solid #afb71d;

    &:hover {
      color: #afb71d;
      background-color: transparent;
      transition: all .6s ease;
      border: 2px solid #afb71d;
    }
  }
}

.footer-main-container {
  background-color: #1a1a1a;
  padding: 50px 0;
}

.footer-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.footer-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-list li {
  margin: 0 50px;
}

.footer-list li a {
  color: #fff;
}

.list-inner {
  background: #fbfbfb;
}

.list-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.list__content {
}

.list__content-half {
  width: 45%;
  padding: 50px 0px;
}

.max-650 {
  max-width: 650px;
  margin: 0 auto;
}

.list__content:nth-child(1) {
  width: 48%;
  border-right: 2px solid #fff;
  padding-right: 50px;
}

.list__content .p-icon {
  text-align: center;
  margin-bottom: 20px;
}

.list__content .p-head {
  text-align: center;
  margin-bottom: 100px;
}

.list__content li {
  color: #000;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 1px;
  position: relative;
  margin-bottom: 30px;
  font-weight: 600;
}

.list__content li:before {
  content: url('../img/round.png');
  position: absolute;
  left: -2em;
}

.team-inner {
  background: #f9fafb;
  padding: 50px 0px;
  margin-top: 50px;
}

.team-box-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team-b {
  width: 46%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.team-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.team-avatar {
  margin-right: 20px;
}

.team-avatar img {
  border-radius: 50%;
}

.team-head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.team-name {
  float: left;
}

.team-name {
  margin-bottom: 10px;
  overflow: hidden;
}

.team-name > h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.team-name > span {
  color: #294354;
  font-size: 16px;
  text-align: justify;
  font-weight: 500;
  letter-spacing: 1px;
}

.f-w {
  width: 350px;
}

.wrapper_team {
  padding: 60px 0;
}

.wrapper_team .our_team img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.wrapper_team .our_team {
  box-shadow: none;
  margin-bottom: 60px;
  transform: translateY(0px);
  transition: all 0.6s ease 0s;
}

.wrapper_team .our_team .pro_detail {
  text-align: center;
  padding-top: 50px;
}

.wrapper_team .our_team .pro_detail .pro_name {
  margin: 0px 0px 20px;
  font-size: 26px;
  position: relative;
  text-transform: uppercase;
  font-family: BebasNeue;
  letter-spacing: 1px;
  text-align: center;
}

.wrapper_team .our_team .pro_detail .pro_desc {
  display: block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}

.color-green {
  color: #afb71d !important;
}

.team-inner1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 50px;
}

.youngstar-profile-slider-inner1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 50px;
}

.youngstar-profile-slider-social {
  display:flex;
  clear:left;
  float: left;
}

.youngstar-diplomas {
  float: left;
}

.youngstar-skills {
  float: left;
}

.youngstar-bottom-div {
}

.why-right {
  width: 100%;
}

.why-slider-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  padding: 45px;
}

.team-slider {

  height: auto;
  position: relative;
}

.team-slider img {
  border-radius: 50%;
  width: 180px;

}

.team-slider-youngstar {

  height: auto;
  position: relative;

}

.team-slider-youngstar img {
  border-radius: 50%;
  width: 180px;
  margin-right: 50px;
}

.why-slider-one {
  outline: none;
  height: initial !important;
  position: relative;
  z-index: 2;
  padding-top: 80px;
}

.why-slider-inner p {
  font-size: 15px;
  text-align: left;
  margin: 0;
  color: #4c4c4c;
  line-height: 39px;
  letter-spacing: 1px;
  font-weight: 600;
}

.why-slider .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.slick-arrows button {
  margin: 0 5px;
}

.why-slider-left {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  background: #fff;

}

.why-slider-right {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  border-radius: 50%;
  width: 65px;
  height: 65px;
  background: #fff;
}

.why-slider-left img,
.why-slider-right img {
  width: 20px;
  height: 20px;
}

.slick-arrows {
  float: right;
  transform: translateY(55px);
  z-index: 1000;
  position: relative;
}

.why-slider {
  position: initial !important;
}

.why-slider .slick-list {
  z-index: 2;
}

.why-list-image img {
  max-width: 100%;
}

.team-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 40px;
  margin-top: -125px;
}

.team-name1 {
  margin-bottom: 0;
  overflow: hidden;
  margin-top: 40px;
  color: #010101;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
}

.article-inner {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.article__head {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  height: 308px;
  background-size: cover !important;
  border-radius: 10px 10px 0 0px;
}

.article__preview {
  font-size: 16px;
  color: #4c4c4c;
  line-height: 31px;
  font-weight: 500;
  letter-spacing: 0.55px;
  padding: 30px;
}

.article {
  width: 48%;
  border-radius: 10px;
  box-shadow: 0 0 65px 0px rgba(0, 0, 0, 0.13);
}

.logo {
  display: none;
}

.logo-2 {
  display: none;
}

@media (max-width: 1600px) {
  .why-slider-left {
    left: 44%;
  }
}

@media (max-width: 1366px) {
  .navbar-default .navbar-nav > li > a {
    width: auto;
  }
  .btn-sign {
    width: 100px !important;
  }
  .why-slider-left {
    left: 42%;
  }
}

@media (max-width: 1200px) {
  .navbar-default .navbar-nav > li > .social {
    display: none;
  }
  .navbar-default .navbar-nav > li > a {
    padding: 10px 5px;
  }
  .program-list {
    justify-content: space-around;
  }
  .p-box {
    margin: 20px;
  }
  .flex-post__content-list {
    height: auto;
    width: auto;
    margin: 0 20px;
    padding: 30px;
  }
  .flex-inner {
    height: auto;
    padding: 50px 0;
  }
  .flex-post__content-list li {
    color: #000;
    font-size: 15px;
    text-align: justify;
    line-height: 25px;
    letter-spacing: 1px;
    width: auto;
    margin: 5px auto;
  }
  .why-slider-left {
    left: 40%;
  }
}

@media (max-width: 990px) {
  .navbar {
    padding: 0px 10px;
  }
  .navbar-default .navbar-nav > li > a {
    margin: 0 5px;
  }
  .nav > li > a {
    padding: 10px 5px;
  }
  .fw-special-title {
    margin: 0px 0px 50px;
  }
  .navbar-brand {
    width: 140px;
  }
  .navbar-default .navbar-nav > li > a {
    font-size: 12px;
  }
  .overlay {
    height: auto;
  }
  .program-list {
    margin: 50px 0;
  }
  .test-list-inner2 li {
    margin: 10px;
  }
  .test-list-inner2 {
    justify-content: center;
  }
  .contact-heading-inner {
    font-size: 24px;
    font-weight: 600;
  }
  .list__content .p-head {
    text-align: center;
    margin-bottom: 50px;
  }
  .why-slider-inner p {
    font-size: 13px;
    line-height: 25px;
  }
  .team-name1 {
    margin-top: 20px;
  }
  .why-slider-inner {
    padding: 20px;
  }
  .team-box-inner {
    flex-wrap: wrap;
  }
  .team-b {
    width: 100%;
    margin-bottom: 20px;
  }
  .why-slider-right {
    right: 42%;
  }
  .team-slider {
    margin-top: -100px;
  }
  .team-slider img {
    width: auto;
  }
}

@media(min-width: 768px) {
  .logo-2 {
    display: block;
  }
}

@media(max-width: 767px) {
  .list__content {
    width: 100% !important;
    border-right: none;
    padding-right: 0px !important;
    padding: 20px 0px;
  }
  .head-content {
    text-align: justify;
  }
  .why-slider-right,
  .why-slider-left {
    width: 50px;
    height: 50px;
  }
  .list__content li {
    margin-left: 2em;
  }
  .why-slider-inner p {
    text-align: center;
  }
  .foot-top-right ul {
    width: 48%;
    margin-bottom: 20px;
  }
  .team-inner1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    text-align: center;
  }
  .team-slider {
    margin-right: 0px;
  }
  .slick-arrows {
    float: none;
    transform: translateY(0px);
    position: absolute;
    bottom: 15px;
    text-align: center;
    margin: 0px auto;
    left: 0;
    right: 0;
  }
  .landing-home h1,
  .fw-special-title {
    font-size: 30px;

    @media (max-width: 512px) {
      font-size: 24px;
    }
  }
  .fw-special-title {
    margin-bottom: 40px;
  }
  .landing-home h3 {
    font-size: 24px;
    margin: 30px 0 20px;
    @media (max-width: 512px) {
      font-size: 20px;
    }
  }
  .section {
    padding: 25px 0px 0px;
  }
  .fw-btn {
    margin: 50px auto 0px;
    width: auto;
  }
  .contact-main-container {
    padding: 50px 0px 0px;
  }
  .contact-inner p {
    width: 100%;
  }
  .pro-list-box {
    padding: 30px;
    line-height: 35px;
    text-align: justify;
  }

  .flex-inner {
    height: auto;
    padding: 50px 0 100px;
    display: block;
  }

  .logo {
    display: block;
    width: 100%;
    max-width: 100px;
    max-height: 48px;
    line-height: 48px;
  }
}

@media (max-width: 600px) {
  .article-inner {
    flex-wrap: wrap;
  }
  .team-head {
    margin-bottom: 20px;
  }
  .article {
    width: 100%;
    margin-bottom: 20px;
  }
  .landing-home p {
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 25px;
    font-weight: 500;
  }
  .why-slider-right {
    right: 32%;
  }
  .why-slider-left {
    left: 32%;
  }
  .test-list-inner2 {
    flex-direction: column;
  }
  .foot-top-right ul {
    width: 100%;
  }
  .foot-top-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    text-align: center;
  }
  .contact-heading-inner {
    text-align: center;
  }
  .contact-inner {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 400px) {
  .mb-50 {
    margin-bottom: 30px !important;
  }
  .landing-home p {
    display: none;
  }
  .p-box {
    padding: 15px;
    box-shadow: 0 15px 54px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    width: 100%;
    margin: 0 0 20px 0;
  }
  .fw-special-title {
    font-size: 24px;
    margin: 0px 0px 30px;
  }
  .contact-heading-inner {
    font-size: 18px;
    font-weight: 600;
  }
  .contact-inner p {
    line-height: 30px;
  }
  .contact-inner {
    a, button {
    }
  }
}

.contact-button {
  padding: 0 10px;
  height: auto;
  line-height: 50px;
}

.add-text-design {
  ul {
    height: 410px;
    width: 1170px;
    max-width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 65px 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 auto;

    @media (max-width: 1200px) {
      height: auto;
      padding: 35px 0;
    }

    li {
      color: #000;
      font-size: 15px;
      text-align: justify;
      line-height: 30px;
      letter-spacing: 1px;
      width: 710px;
      max-width: 100%;
      margin: 0 auto;
      @media (max-width: 776px) {
        font-size: 12px;
      }

      &:before {
        background: #afb71d;
        content: "";
        display: inline-block;
        height: 9px;
        position: relative;
        vertical-align: middle;
        width: 9px;
        margin-right: 20px;
        border-radius: 50%;
      }
    }
  }
  p {
    font-size: 16px;
    color: #4c4c4c;
    line-height: 42px;
    letter-spacing: 0.55px;
    font-weight: 600;
    width: 100%;
    @media (max-width: 776px) {
      font-size: 12px;
      line-height: 24px;
    }
  }
}

.add-text-design-2 {
  ul {
    max-width: 650px;
    margin: 0 auto;
    list-style: none;
    padding: 0;

    @media (max-width: 1200px) {
      height: auto;
      padding: 35px 0;
    }
    li {
      color: #000;
      font-size: 15px;
      text-align: justify;
      line-height: 30px;
      letter-spacing: 1px;
      position: relative;
      margin-bottom: 30px;
      font-weight: 600;
      @media (max-width: 776px) {
        font-size: 12px;
      }

      &:before {
        background: #afb71d;
        content: "";
        display: inline-block;
        height: 9px;
        position: relative;
        vertical-align: middle;
        width: 9px;
        margin-right: 20px;
        border-radius: 50%;
      }
    }
  }
  p {
    font-size: 16px;
    color: #4c4c4c;
    line-height: 42px;
    letter-spacing: 0.55px;
    font-weight: 600;
    @media (max-width: 776px) {
      font-size: 12px;
      line-height: 24px;
    }
  }
}

.add-text-design-3 {
  display: flex;
  justify-content: center;

  ul {
    background: #fff;
    border-radius: 10px;
    padding: 65px 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: 5px;
    min-height: 491px;

    @media (max-width: 1200px) {
      height: auto;
      padding: 35px;
      min-height: 391px;
    }
    li {
      color: #000;
      font-size: 15px;
      text-align: justify;
      line-height: 30px;
      letter-spacing: 1px;
      margin: 0 auto;
      width: 100%;
      @media (max-width: 776px) {
        font-size: 12px;
        line-height: 24px;
        letter-spacing: initial;
        margin-bottom: 25px;
        &:last-child{
          margin-bottom: 0;
        }
      }

      &:before {
        background: #afb71d;
        content: "";
        display: inline-block;
        height: 9px;
        position: relative;
        vertical-align: middle;
        width: 9px;
        margin-right: 20px;
        border-radius: 50%;
      }
    }
  }
}

.contact-form {
  padding: 45px 45px;
  box-shadow: 0 15px 54px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: #fff;
  color: #4c4c4c;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  transition: all 0.6s ease-in-out;
  border: 2px solid #f6f6f6;
  position: relative;
}
.contact-message--success{
  font-size: 22px;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}
.contact-icon--success {
  color: #00b709;
  font-size: 42px;
  display: block;
  text-align: center;
  padding-bottom: 10px;
}

.logo-2 {
  width: 285px;
  height: auto;
  position: absolute;
  top: -10px;

  padding: 25px 10px 10px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /*background-color: #a51010;*/
  /*background-color: #ebebeb;*/
  background-color: white;
  border-bottom: 2px solid #9c9c9c;

  -webkit-transition: transform 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;

  z-index: 1001; /* Get Over Our Menu */

  &:hover {
    transform: translateY(10px);
  }

  img {
    width: 100%;
    max-height: 100%;
  }

  @media (max-width: 1600px) {
    width: 285px;
  }
  @media (max-width: 1200px) {
    width: 220px;
  }
  @media (max-width: 991px) {
    width: 140px;
  }
}

.coach {
  text-decoration: none;
  color: inherit;

  .our_team {
    position: relative;

    .linkedin {
      position: absolute;
      width: 40px;
      height: 40px;
      top: calc(360px - 40px);
      border-radius: 0;
      right: 0;
      filter: grayscale(100%);
    }
  }

  &:hover {
    .linkedin {
      filter: grayscale(0%);
    }
  }
}

.white-links a {
  color: white;
  text-decoration: underline !important;
}

.minimised-title-box{
  margin-bottom:10px;
}

.contact-footer{
  margin-top:30px;
}

// MM added in 2020
.form-group.required label.control-label:after{
  content: ' *';
  color: #d82c11;
}

h3.fw-special-title {
  font-size: 32px;
}
h1, h2, h3, .fw-special-title {
  margin-bottom: 25px !important;
}
a {
  color: #afb71d;
  text-decoration: none;
  font-weight: 700;
  transition: all 0.1s ease-in-out;

  &:hover{
    color: #9aa21c;
  }
  &:active {
    color: #717916;
  }
}


.listing-container{
  h1, h2, h3, h4 {
    position: relative;
    text-transform: uppercase;
    font-family: BebasNeue, serif;
    letter-spacing: 4px;
  }
  p {
    min-height: 4rem;
  }
}
